import {EagleApiInterface, EagleModuleApiInterface} from 'kernel/api/api'

export default (api: EagleApiInterface) => {
    api.collection.storeApi = <EagleModuleApiInterface>{
        index: (store_id) => api.request({
            url: `/api/store/${store_id}/mangers`,
            type: 'get',
        }),
        // 供應商index
        coProviderIndex: (store_id, params = {}) => api.request({
            url: `/api/store/${store_id}/providers/index`,
            type: 'get',
            params
        }),
        // 如果store跟provider沒有任何關係, 會返回404
        readByProviderAndStore: (store_id: string, provider_id: string) => api.request({
            url: `/api/store/${store_id}/provider/${provider_id}`,
            type: 'get',
        }),
        // read: (store_id: string) => api.request({
        //   url: `/api/store/${store_id}`,
        //   type: 'get',
        // }),
        create: (params = {}) => api.request({
            url: `/api/store`,
            type: 'post',
            params,
        }),
        update: (storeId, providerId, params = {}) => api.request({
            url: `/api/store/${storeId}/provider/${providerId}/update`,
            type: 'put',
            params,
        }),
        applyProvider: (storeId, providerId, params = {}) => api.request({
            url: `/api/store/${storeId}/provider/${providerId}/apply-provider`,
            type: 'post',
            params,
        }),
        join: (storeId, providerId, params) => api.request({
            url: `/api/store/${storeId}/provider/${providerId}/join`,
            type: 'post',
            params
        }),
        // 取消合作
        cancel: (providerId, storeId) => api.request({
            url: `/api/store/${storeId}/provider/${providerId}/delete`,
            type: 'delete',
        }),
        // 檢查或建立pivot
        checkPivot: (provider_id, params) => api.request({
            url: `/api/member/verify/memberProviderPivot/${provider_id}`,
            type: 'post',
            params
        }),
        inviteConfig: (storeId, params = {}) => api.request({
            url: `/api/store/${storeId}/invite-config`,
            type: 'get',
            params,
        }),
        relationLineNotifyAccessToken: (store_id, code) =>
            api.request({
                url: `/api/line-notify/store/${store_id}/relation-access-token/${code}`,
                type: "post",
            }),
        getLineNotifyListByStoreId: (store_id) =>
            api.request({
                url: `/api/line-notify/store/${store_id}`,
                type: "get",
            }),
    }

    api.collection.storeProductApi = <EagleModuleApiInterface>{
        // api/store/{store}/product/{provider}/index
        index: (store_id: string, provider_id: string, params = {}) => api.request({
            url: `/api/store/${store_id}/product/${provider_id}/index`,
            type: 'get',
            params,
        }),
    }

    api.collection.cartApi = <EagleModuleApiInterface>{
        add: (storeId, providerId, params = {}) => api.request({
            url: `/api/store/${storeId}/provider/${providerId}/cart/create`,
            type: 'post',
            params,
        }),
        read: (storeId, providerId) => api.request({
            url: `/api/store/${storeId}/provider/${providerId}/cart/read`,
            type: 'get',
        }),
        update: (storeId, providerId, params = {}) => api.request({
            url: `/api/store/${storeId}/provider/${providerId}/cart/update`,
            type: 'put',
            params,
        }),
        delete: (storeId, providerId, params = {}) => api.request({
            url: `/api/store/${storeId}/provider/${providerId}/cart/delete`,
            type: 'delete',
            params,
        }),
    }

    api.collection.orderApi = <EagleModuleApiInterface>{
        index: (storeId, providerId, params = null) => api.request({
            url: `/api/store/${storeId}/provider/${providerId}/orders/index`,
            type: 'get',
            params,
        }),
        read: (storeId, providerId, orderId) => api.request({
            url: `/api/store/${storeId}/provider/${providerId}/orders/${orderId}/read?getReason=true`,
            type: 'get',
        }),
        update: (storeId, providerId, orderId, params) => api.request({
            url: `/api/store/${storeId}/provider/${providerId}/orders/${orderId}`,
            type: 'put',
            headers: {
                "Content-Type": 'application/x-www-form-urlencoded'
            },
            params
        }),
        // 建立訂單
        create: (storeId, providerId, params = {}) => api.request({
            url: `/api/store/${storeId}/provider/${providerId}/orders/create`,
            type: 'post',
            params,
        }),
        applyBack: (storeId, providerId, orderId, params = {}) => api.request({
            url: `/api/store/${storeId}/provider/${providerId}/orders/${orderId}/applyBack`,
            type: 'post',
            headers: {
                "Content-Type": 'application/x-www-form-urlencoded'
            },
            params,
        }),
        paymentByLinePay: (params) => api.request({
            url: `/api/linepay`,
            type: 'post',
            params
        }),
        paymentConformByLinePay: (params) => api.request({
            url: `/api/linepay/confirm`,
            type: 'post',
            params
        }),
        paymentByECPay: (params) => api.request({
            url: `/api/ecpay`,
            type: 'post',
            params
        }),
        paymentResult: (paymentRecordId) => api.request({
            url: `/api/payment/${paymentRecordId}`,
            type: 'get',
        }),
    }

    api.collection.orderAllApi = <EagleModuleApiInterface>{
        index: (storeId, providerId, params = null) => api.request({
            url: `/api/order/my-provider/index`,
            type: 'get',
            params,
        }),
    }

    api.collection.favoriteApi = <EagleModuleApiInterface>{
        index: (store_id, provider_id) => api.request({
            url: `/api/store/${store_id}/provider/${provider_id}/favorite/products`,
            type: 'get',
        }),
        update: (store_id, provider_id, product_id, params) => api.request({
            url: `/api/store/${store_id}/provider/${provider_id}/favorite/product/${product_id}`,
            type: 'put',
            params
        }),
        delete: (store_id, provider_id, product_id) => api.request({
            url: `/api/store/${store_id}/provider/${provider_id}/favorite/product/${product_id}`,
            type: 'delete',
        }),
    }

    api.collection.receivingDateApi = <EagleModuleApiInterface>{
        index: (store_id) => api.request({
            // 取得可收貨時間列表
            url: `/api/store/${store_id}/receiving-date/index`,
            type: 'get',
        }),
        add: (store_id, params = {}) => api.request({
            // 新增可收貨時間列表
            url: `/api/store/${store_id}/receiving-date`,
            type: 'post',
            params
        }),
        update: (store_id, day_id, params = {}) => api.request({
            // 新增可收貨時間列表
            url: `/api/store/${store_id}/receiving-date/${day_id}`,
            type: 'put',
            params
        }),
    }

}
